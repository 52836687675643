import { DateBox, Popup, SelectBox, TextArea, TextBox } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import { FunctionComponent, useEffect, useState } from "react";
import { useMsalToken } from "../../hooks/useToken";
import { EvalAnswer, EvalQuestion, EvalSection, EvalServiceItem } from "../../models/evaluation";
import { GetServiceItemsForTeamAsync } from "../../services/http-service";
import { ValidationGroup } from "../../types/validation";
import { RequiredValidator } from "../Validator";
import { Employee } from "../../models/employee";

export const EvalFillFinish: FunctionComponent<{
    visible: boolean,
    onSubmit: (serviceItem?: EvalServiceItem, notes?: string, callId?: string, callDate?: Date) => void,
    evalUid: string,
    evalVersion: number,
    initNotes?: string,
    initServiceItem?: EvalServiceItem,
    initCallId?: string,
    initCallDate?: Date,
}> = ({ visible, onSubmit, evalUid, evalVersion, initNotes, initServiceItem, initCallId, initCallDate }) => {
    const token = useMsalToken();
    const [serviceItems, setServiceItems] = useState<EvalServiceItem[]>();
    const [selectedServiceItem, setSelectedServiceItem] = useState(initServiceItem);
    const [notes, setNotes] = useState(initNotes);
    const [callId, setCallId] = useState(initCallId);
    const [callDate, setCallDate] = useState(initCallDate);

    useEffect(() => {
        if (!token) return;
        setNotes(initNotes);
        setCallId(initCallId);
        setCallDate(initCallDate)
        setSelectedServiceItem(initServiceItem);
        GetServiceItemsForTeamAsync(evalUid, evalVersion, token)
            .then(d => {
                if (d.ok) {
                    setServiceItems(d.data!);
                } else {
                    notify(`There was an error: ${d.error?.message}`, "error");
                }
            });
    }, [evalUid, evalVersion, token, initNotes, initServiceItem, callId, callDate]);

    return (
        <Popup
            visible={visible}
            showCloseButton={false}
            hideOnOutsideClick={false}>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={{ text: "Submit", onClick: () => onSubmit(selectedServiceItem, notes, callId, callDate) }} />
            <div className="container">
                <div className="row">
                    <SelectBox
                        value={selectedServiceItem}
                        displayExpr="name"
                        placeholder="Select a service item..."
                        label="Service Item:"
                        onValueChanged={(v) => setSelectedServiceItem(v.value)}
                        showClearButton={true}
                        dataSource={serviceItems} />
                </div>
                <hr className="my-4" />
                <div className="row">
                    <div className="col">
                        <TextBox label="Call ID:" maxLength={80} value={callId} onValueChanged={(v) => setCallId(v.value)} />
                    </div>
                    <div className="col">
                        <DateBox label="Call Date:" value={callDate} onValueChanged={(v) => setCallDate(v.value)} dateSerializationFormat="yyyy-MM-dd" />
                    </div>
                </div>
                <hr className="my-4" />
                <div className="row">
                    <TextArea maxLength={500} value={notes} onValueChanged={(v) => setNotes(v.value)} label="Notes:" placeholder="Write some notes..." />
                </div>
            </div>
        </Popup>
    )
};

export const EvalFillHeader: FunctionComponent<{
    section: EvalSection,
    employee?: Employee,
    onNext?: () => void,
    onPrevious?: () => void,
    onFinish?: () => void,
}> = ({ section, employee, onNext, onPrevious, onFinish }) => {
    return (
        <div className="row border rounded-2 bg-light mx-1 my-1 py-3 text-center align-items-center fs-4 fw-semibold">
            {onPrevious &&
                <div className="col-sm-1" >
                    <button type='button' className='btn btn-lg btn-primary' style={{ color: 'white' }} onClick={onPrevious}>Previous</button>
                </div>
            }
            <div className="col" >
                {section.sectionName}
                {employee ? ' - ' + employee.firstName + ' ' + employee.lastName : ''}
            </div>
            {onNext &&
                <div className="col-sm-1" >
                    <button type='button' className='btn btn-lg btn-primary' style={{ color: 'white' }} onClick={onNext}>Next</button>
                </div>
            }
            {onFinish &&
                <div className="col-sm-1" >
                    <button type='button' className='btn btn-lg btn-primary' style={{ color: 'white' }} onClick={onFinish}>Submit</button>
                </div>
            }
        </div>
    )
};

export const EvalFillQuestion: FunctionComponent<{
    index: number,
    question: EvalQuestion,
    answers: EvalAnswer[],
    selectedAnswer?: Map<string, string | undefined>,
    onAnswerChanged: (answer?: string) => void,
    validationGroup?: ValidationGroup,
    note?: string,
    onNoteChanged: (answer?: string) => void,
}> = ({ index, question, answers, selectedAnswer, onAnswerChanged, validationGroup, note, onNoteChanged }) => {
    return (
        <div className="row border rounded-2 bg-white mx-3 my-1 py-4 align-items-center fs-5">
            <div className="row mb-3">
                <div className="col col-sm-1 text-center">
                    {index}
                </div>
                <div className="col" >
                    <div>{question.question}</div>
                </div>
                <div className="col col-sm-3">
                    <select
                        value={selectedAnswer?.get(question.uid!)}
                        onChange={e => { onAnswerChanged(e.currentTarget?.value); }}
                        className="form-select"
                        name="answers"
                        id="answers">
                        <option value={undefined}></option>
                        {answers.map((a, i) =>
                            <option key={i} value={a.uid}>{a.answer}</option>
                        )}
                    </select>
                    {validationGroup &&
                        <RequiredValidator group={validationGroup} value={selectedAnswer?.get(question.uid!)} />
                    }
                </div>
            </div>
            <div className="row" >
                <div className="col input-group">
                    <span className="input-group-text">Notes</span>
                    <input maxLength={100} id="note-input" type="text" className="form-control" value={note} onChange={e => { onNoteChanged(e.currentTarget?.value) }} />
                </div>
            </div>
        </div>
    )
};