import { FunctionComponent, useEffect, useState } from 'react';
import './App.scss';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import Nav, { NavAnchor, NavButton, NavLink } from './components/nav/Nav';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSilentLogin } from './hooks/useSilentLogin';
import { EvalEditor } from './pages/eval-editor/EvalEditor';
import { EvalEditorAnswers } from './pages/eval-editor-answers/EvalEditorAnswers';
import { EvalList } from './pages/eval-list/EvalList';
import { EvalFill } from './pages/eval-fill/EvalFill';
import { ANSWERS_URI, EDITOR_URI, FILL_URI, LIST_URI, RESULTS_URI, TEAMS_URI } from './constants';
import { Teams } from './pages/teams/Teams';
import { EvalResults } from './pages/eval-results/EvalResults';
import { FullSpinner } from './components/spinner/Spinner';
import { HasAccessToEval } from './services/http-service';
import config from 'devextreme/core/config';

type AppProps = {
  pca: IPublicClientApplication
};

const App: FunctionComponent<AppProps> = ({ pca }: AppProps) => {
  const l = useSilentLogin(pca); //  try to login silently if possible
  const [hasAccess, setHasAccess] = useState<boolean>();
  const [token, setToken] = useState<string>();
  config({
    licenseKey: process.env.REACT_APP_LICENSE_KEY
  });

  useEffect(() => {
    if (!token) return;
    HasAccessToEval('Bearer ' + token)
      .then(d => {
        if (d.ok) {
          setHasAccess(d.data?.hasAccess);
        }
      })
  }, [token]);

  const handleLogin = () => {
    pca.loginPopup({
      scopes: ["User.Read"]
    })
      .then(r => {
        pca.setActiveAccount(r.account);
        setToken(r.idToken);
      });
  }

  useEffect(() => {
    if (!l) return;
    const acc = pca.getAccountByUsername(l);
    pca.setActiveAccount(acc);
    pca.acquireTokenSilent({ scopes: ["User.Read"] })
      .then(r => {
        setToken(r.idToken);
      });
  }, [l, pca]);

  const handleLogout = () => {
    pca.logoutPopup({
      idTokenHint: ""
    });
    sessionStorage.clear();
  }

  return (
    <MsalProvider instance={pca}>
      <div className="App">
        <UnauthenticatedTemplate>
          <div className="h-100 text-center d-flex justify-content-center align-items-center bg-primary">
            <button type="button" onClick={handleLogin} className='btn btn-secondary bg-white text-dark border-2 fw-bold fs-4' style={{ height: '120px', width: '240px' }}>LOGIN</button>
          </div>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          {hasAccess === true &&
            <BrowserRouter>
              <Nav>
                <NavLink path={LIST_URI} name='Evaluations' />
                <NavLink path={ANSWERS_URI} name='Answers' />
                <NavLink path={TEAMS_URI} name='Teams' />
                <NavLink path={RESULTS_URI} name='Results' />

                <div style={{ float: "right", margin: "auto", marginRight: "0" }}>
                  <span className='text-muted'>version {process.env.REACT_APP_VERSION}</span>
                  <NavAnchor path='https://cdn.az.mellongroup.com/mellon/AgentEvaluationManual.pdf' name='Manual' />
                  <NavButton onClick={(_) => { handleLogout() }} text="Logout"></NavButton>
                </div>
              </Nav>
              <Routes>
                <Route path="/" element={<h1 className='welcome-text'>Welcome to the Agent Evaluation Application</h1>} />
                <Route path={LIST_URI} element={<EvalList />} />
                <Route path={FILL_URI} element={<EvalFill />} />
                <Route path={EDITOR_URI} element={<EvalEditor />} />
                <Route path={ANSWERS_URI} element={<EvalEditorAnswers />} />
                <Route path={TEAMS_URI} element={<Teams />} />
                <Route path={RESULTS_URI} element={<EvalResults />} />
              </Routes>
            </BrowserRouter>
          }
          {hasAccess === undefined &&
            <FullSpinner />
          }
          {hasAccess === false &&
            <div className='fw-semibold fs-4 text-center mt-4'>No Access.</div>
          }
        </AuthenticatedTemplate>
      </div >
    </MsalProvider >
  );
}

export default App;
